import './App.css';
import React, { Fragment } from 'react';
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'
import { pembilang, api, saiki, tanggalIndo, numberFormat, exportData } from './Modul';

function App() {
  let Token = localStorage.getItem("TokenData");
  let Path = window.location.pathname;
  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        D1: saiki(),
        D2: saiki(),
        D1Format: tanggalIndo(saiki()),
        D2Format: tanggalIndo(saiki()),
        sD1: saiki(),
        sD2: saiki(),
        sD1Format: tanggalIndo(saiki()),
        sD2Format: tanggalIndo(saiki()),
        Data: [],
        Field: [
          { "cap": "Date", "sort": "DocDate", "type": "date" },
          { "cap": "Faktur Pajak", "sort": "FakturPajak" },
          { "cap": "No. Reff", "sort": "DocNumber" },
          { "cap": "No. Invoice", "sort": "Invoice" },
          { "cap": "No. Surat Jalan", "sort": "NomorSuratJalan" },
          { "cap": "No. SO", "sort": "NomorSO" },
          { "cap": "Nama", "sort": "CardDetailName" },
          { "cap": "Nominal", "sort": "GrandTotal", "type": "number" },
          { "cap": "Profile", "sort": "Nama" },
        ],
        Detail: {},
        DataDetail: [],
        DataProfile: [],
        DetailProfile: {},
        Profile: "",
        DataTrans: [],
        DataTransChoose: [],
        PrintNotes: "BCA :	PT. TIMUR MAKMUR SENTOSA (215-5755-588)",
        Admin: "Risma",
        Atasan: "Zulham",
        Sopir: "MUS",
        ModePrint: "invoice",
        DomPrintPreview: [],
        Lokasi: "",
        QtyResource: "CQtyUnit",
        DataLokasi: [],
        isPreview: true,
        DataWH: [],
        DetailWH: {},
        qwh: ""
      };
    }

    async componentDidMount() {
      if (Path == "/") {
        let sql = await api("/api", { act: "data profile" });
        if (sql.status == "sukses") this.setState({ DataProfile: sql.data });
        this.handleMain();
      } else {
        let sql = await api("/api", { act: "detail", DocNumber: Path.replaceAll("/", ""), Profile: 'pt.menaraagungperkasa' });
        if (sql.status == "sukses") {
          this.setState({ Detail: sql.data, DataDetail: sql.detail, DetailProfile: sql.profile, Admin: sql.profile.Admin, Atasan: sql.profile.Atasan, Sopir: sql.profile.Sopir, PrintNotes: sql.profile.PrintNotes });
          this.handleCetak();
        } else {
          alert("data tidak di temukan");
        }
      }
    }

    async handleMain() {
      let sql = await api("/api", { act: "data", D1: this.state.D1, D2: this.state.D2, DocType: this.state.DocType });
      if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    handleLogout() {
      localStorage.removeItem("TokenData");
      window.location.reload();
    }

    async handleModalTarikData() {
      let sql = await api("/api", { act: "data profile" });
      if (sql.status == "sukses") this.setState({ DataProfile: sql.data, DataTrans: [] });
      document.getElementById('btnSubmit').disabled = false;
      const myModal = new window.bootstrap.Modal('#modalTarikData', {})
      myModal.show('#modalTarikData');
    }

    async handleCariTrans(e) {
      if (this.state.Profile != "") {
        e.target.disabled = true;
        e.target.innerText = 'Mencari.';
        let sql = await api("/api", { act: "data sales", D1: this.state.sD1, D2: this.state.sD2, Profile: this.state.Profile });
        if (sql.status == "sukses") {
          e.target.disabled = false;
          e.target.innerText = 'Cari';
          this.setState({ DataTrans: sql.data });
        }
      } else {
        alert('Silahkan pilih profile');
      }
    }

    HandleChooseAll(e) {
      let data = this.state.DataTrans;
      if (e.target.checked == true) {
        for (let dd in data) data[dd].Choose = 1;
      } else {
        for (let dd in data) data[dd].Choose = 0;
      }
      this.setState({ DataTrans: data });
    }

    handleChooseTrans(e, i) {
      let data = this.state.DataTrans;
      if (e.target.checked == true) {
        data[i].Choose = 1;
      } else {
        data[i].Choose = 0;
      }
      this.setState({ DataTrans: data });
    }

    async handleTarikData(e) {
      e.preventDefault();
      e.stopPropagation();
      let data = this.state.DataTrans;
      let Choose = [];
      for (let dd of data) if (dd.Choose == 1) Choose.push(dd.DocNumber);

      if (Choose.length > 0) {
        document.getElementById('btnSubmit').disabled = true;
        let sql = await api("/api", { act: "proses tarik", data: Choose, Profile: this.state.Profile });
        if (sql.status == "sukses") {
          this.handleMain();
          document.getElementById('btnSubmit').disabled = false;
          document.getElementById('btnTutupTrans').click();
        } else {
          document.getElementById('btnSubmit').disabled = false;
          alert("Terjadi kesalah ketika memproses");
        }
      } else {
        alert("Silahkan pilih data terlebih dahulu");
      }
    }

    async modalDetail(data) {
      let sql = await api("/api", { act: "detail", DocNumber: data.DocNumber, Profile: data.Profile });
      if (sql.status == "sukses") {
        let detail = sql.detail;
        let Data = sql.data;
        Data.AmountOther = numberFormat(Data.AmountOther);
        for (let dd in detail) {
          let Qty = detail[dd].CQtyUnit;
          let Price = detail[dd].CPrice;
          let Total = detail[dd].CTotal;

          detail[dd].CQtyUnit = Qty;
          detail[dd].CPrice = Price;
          detail[dd].CTotal = Total;

          detail[dd].CQtyUnit = numberFormat(detail[dd].CQtyUnit);
          detail[dd].CPrice = numberFormat(detail[dd].CPrice);
          detail[dd].CTotal = numberFormat(detail[dd].CTotal);
        }
        this.setState({ Detail: Data, DataDetail: detail });
        const myModal = new window.bootstrap.Modal('#modalDetail', {});
        myModal.show('#modalDetail');
      } else {
        alert("data tidak di temukan");
      }
    }

    async handleEdit(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn1 = document.getElementById('btnSubmit1');
      let btn2 = document.getElementById('btnSubmit2');
      btn1.disabled = true;
      btn2.disabled = true;
      if (Form.checkValidity()) {
        let GrandTotal = 0;
        let Header = this.state.Detail;
        Header.AmountOther = Header.AmountOther.replaceAll(",", "");
        let Detail = this.state.DataDetail;
        for (let dd in Detail) {
          let Qty = Detail[dd].CQtyUnit;
          let Price = Detail[dd].CPrice;
          let Total = Detail[dd].CTotal;
          Detail[dd].CQtyUnit = Qty.replaceAll(",", "");
          Detail[dd].CPrice = Price.replaceAll(",", "");
          Detail[dd].CTotal = Total.replaceAll(",", "");
          GrandTotal += parseInt(Total.replaceAll(",", ""));
        }
        Header.GrandTotal = GrandTotal;
        let mode = this.state.modeEdit;
        let sql = await api("/api", { act: "update data", DocNumber: this.state.Detail.DocNumber, Header: Header, Detail: Detail });
        if (sql.status == "sukses") {
          if (mode == "Edit") {
            btn1.disabled = false;
            btn2.disabled = false;
            this.handleMain();
            document.getElementById('btnTutupModalEdit').click();
          } else {
            btn1.disabled = false;
            btn2.disabled = false;
            this.modalSendPrint(Header);
            document.getElementById('btnTutupModalEdit').click();
          }
        } else {
          alert("Terjadi kesalahan ketika memproses");
          btn1.disabled = false;
          btn2.disabled = false;
        }
      } else {
        Form.classList.add('was-validated');
        btn1.disabled = false;
        btn2.disabled = false;
      }
    }

    modalDelete(data) {
      this.setState({ Detail: data });
      let myModal = new window.bootstrap.Modal('#modalDelete', {});
      myModal.show('#modalDelete');
    }

    async handleDelete(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      let sql = await api("/api", { act: "hapus data", Data: this.state.Detail });
      if (sql.status == "sukses") {
        btn.disabled = false;
        this.handleMain();
        document.getElementById('btnTutupModalDelete').click();
      } else {
        alert("Terjadi kesalahan ketika memproses");
        btn.disabled = false;
      }
    }

    async modalCetak(data) {
      let sql = await api("/api", { act: "detail", DocNumber: data.DocNumber, Profile: data.Profile });
      if (sql.status == "sukses") {
        this.setState({ IsPreview: true, Detail: sql.data, DataDetail: sql.detail, DetailProfile: sql.profile, DataLokasi: sql.lokasi, Admin: sql.profile.Admin, Atasan: sql.profile.Atasan, Sopir: sql.profile.Sopir, PrintNotes: sql.profile.PrintNotes });
        const myModal = new window.bootstrap.Modal('#modalCetak', {});
        myModal.show('#modalCetak');
      } else {
        alert("data tidak di temukan");
      }
    }

    async handleCetak() {
      let DataTemp = this.state.DataDetail;
      let Dom = [];
      let Page = Math.ceil(DataTemp.length / 12);
      let Sisa = [];
      if (this.state.ModePrint == "invoice") {
        let CurPG = 12, CurI = 0;
        let SubTotal = 0,
          UangMuka = this.state.Detail.AmountOther,
          Total = 0,
          DPP = 0,
          PPN = 0;
        for (let dd of this.state.DataDetail) SubTotal += parseInt(dd.CTotal);
        Total = SubTotal - UangMuka;
        DPP = Math.round(SubTotal / 1.11);
        PPN = Math.round(DPP * 11 / 100);
        let DomSisa = [];
        for (let dd = 0; dd < 12 - Sisa.length; dd++) {
          DomSisa.push(<p style={{ color: "transparent", marginTop: "0", marginBottom: "0" }} > {dd}</ p>)
        }
        DomSisa.push(<i>Halaman {Page} dari {Page}</i>);

        for (let pg = 1; pg <= Page; pg++) {
          let tr = [];
          Sisa = [];
          for (let i = CurI; i < CurPG; i++) {
            let dd = DataTemp[i];
            if (dd) {
              tr.push(<tr key={dd.ID}>
                <td style={{ fontSize: "10px", textAlign: "center", width: "40px" }}>{i + 1}</td>
                <td style={{ fontSize: "10px", textAlign: "left" }}>{dd.ItemName}</td>
                <td style={{ fontSize: "10px", textAlign: "center" }}>{numberFormat(dd.CQtyUnit)}</td>
                <td style={{ fontSize: "10px", textAlign: "center" }}>{dd.CUnit}</td>
                <td style={{ fontSize: "10px", textAlign: "right" }}>{numberFormat(dd.CPrice)}</td>
                <td style={{ fontSize: "10px", textAlign: "right" }}>Rp. {numberFormat(dd.CTotal)}</td>
              </tr>);
              Sisa.push(dd);
            }
          }

          Dom.push([
            <Fragment>
              <div className='a5-container'>
                <div className='page-break'></div>
                <h4 style={{ textAlign: "center", fontSize: "16px" }}>Nota</h4>
                <div className='d-flex justify-content-between align-items-start'>
                  <table>
                    <tbody>
                      <tr> <td>Tanggal</td> <td>:</td> <td>{tanggalIndo(this.state.Detail.DocDate)}</td></tr>
                      <tr> <td>Faktur Pajak</td> <td>:</td> <td>{this.state.Detail.FakturPajak}</td> </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td>No. Data</td>
                        <td>:</td>
                        <td>{this.state.Detail.Invoice}</td>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>:</td>
                        <td>{this.state.Detail.CardName}</td>
                      </tr>
                      <tr>
                        <td>N.P.W.P</td>
                        <td>:</td>
                        <td>{this.state.Detail.TaxIDNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p></p>
                <table className='table table-bordered table-sm' style={{ marginBottom: "0" }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center", width: "40px", fontSize: "11px" }}>No.</th>
                      <th style={{ textAlign: "center", fontSize: "11px" }}>Nama Barang</th>
                      <th style={{ textAlign: "center", fontSize: "11px" }}>Qty</th>
                      <th style={{ textAlign: "center", fontSize: "11px" }}>Satuan</th>
                      <th style={{ textAlign: "right", fontSize: "11px" }}>Harga</th>
                      <th style={{ textAlign: "right", fontSize: "11px" }}>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tr}
                  </tbody>
                </table>
                {
                  Page != pg ? <i className="bottom-element">Halaman {pg} dari {Page}</i> : <i></i>
                }
                {
                  Page == pg ?
                    <Fragment>
                      <p></p>
                      <div className='row'>
                        <div className='col-8'>
                          <h6 style={{ fontSize: "11px" }}> Terbilang : {pembilang(Total)}</h6>
                          <h6 style={{ fontSize: "11px", marginTop: "10px" }}>{this.state.PrintNotes}</h6>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='d-flex justify-content-between align-items-start gap-2'>
                                <div style={{ textAlign: "center" }}>
                                  <h6 style={{ paddingBottom: "40px", fontSize: "11px" }}>Administrasi</h6>
                                  <h6 style={{ fontSize: "11px" }}>(  {this.state.Admin}  )</h6>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <h6 style={{ paddingBottom: "40px", fontSize: "11px" }}>Penanggung Jawab</h6>
                                  <h6 style={{ fontSize: "11px" }}>(  {this.state.Atasan}  )</h6>
                                </div>
                              </div>
                            </div>
                            <div className='col-6'></div>
                          </div>
                          {
                            Page == 1 ?
                              <i className={Page == 1 ? "bottom-element" : ""}>Halaman {Page} dari {Page}</i>
                              :
                              DomSisa
                          }
                        </div>
                        <div className='col-4'>
                          <table className='table table-sm table-bordered'>
                            <tbody>
                              <tr> <td>SUBTOTAL</td> <td style={{ textAlign: "right", fontSize: "11px" }}>Rp. {numberFormat(SubTotal)}</td></tr>
                              <tr> <td>Uang Muka</td> <td style={{ textAlign: "right", fontSize: "11px" }}>Rp. {numberFormat(UangMuka)}</td></tr>
                              <tr> <td>Total</td> <td style={{ textAlign: "right", fontSize: "11px" }}>Rp. {numberFormat(Total)}</td></tr>
                              <tr> <td>DPP</td> <td style={{ textAlign: "right", fontSize: "11px" }}>Rp. {numberFormat(DPP)}</td></tr>
                              <tr> <td>PPN 11%</td> <td style={{ textAlign: "right", fontSize: "11px" }}>Rp. {numberFormat(PPN)}</td></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Fragment> :
                    <div></div>
                }
              </div>
            </Fragment>]);
          CurPG += 12;
          CurI += 12;
        }
      } else {
        let tr = [];
        for (let i = 0; i < 2; i++) {
          let dd = DataTemp[i];
          if (dd) {
            tr.push(<tr key={dd.ID}>
              <td style={{ textAlign: "center" }}>{i + 1}</td>
              <td style={{ textAlign: "left" }}>{dd.ItemName}</td>
              <td style={{ textAlign: "center" }}>{numberFormat(dd.CQtyUnit)}</td>
              <td style={{ textAlign: "center" }}>{dd.CUnit}</td>
              <td style={{ textAlign: "left" }}>{dd.Notes}</td>
            </tr>);
          }
        }

        Dom = [<Fragment>
          <h4 style={{ textAlign: "center" }}>Surat Jalan</h4>

          <div className='d-flex justify-content-between align-items-start'>
            <table>
              <tbody>
                <tr> <td>Tanggal</td> <td>:</td> <td>{tanggalIndo(this.state.Detail.DocDate)}</td></tr>
                <tr> <td>No. SJ</td> <td>:</td> <td>{this.state.Detail.NomorSuratJalan}</td></tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr> <td>Alamat</td> <td>:</td> <td>{this.state.Detail.AlamatKirim}</td></tr>
                <tr> <td>Nama</td> <td>:</td> <td>{this.state.Detail.CardName}</td></tr>
              </tbody>
            </table>
          </div>
          <p></p>
          <table className='table table-bordered table-sm' style={{ marginBottom: "0" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>No.</th>
                <th style={{ textAlign: "center" }}>Nama Barang</th>
                <th style={{ textAlign: "center" }}>Qty</th>
                <th style={{ textAlign: "center" }}>Satuan</th>
                <th style={{ textAlign: "center" }}>Keterangan</th>
              </tr>
            </thead>
            <tbody>
              {tr}
            </tbody>
          </table>
        </Fragment>];

        if (DataTemp.length > 12) {
          let tr = [];
          for (let i = 12; i < 22; i++) {
            let dd = DataTemp[i];
            if (dd) {
              tr.push(<tr key={dd.ID}>
                <td style={{ textAlign: "center" }}>{i + 1}</td>
                <td style={{ textAlign: "left" }}>{dd.ItemName}</td>
                <td style={{ textAlign: "center" }}>{numberFormat(dd.CQtyUnit)}</td>
                <td style={{ textAlign: "center" }}>{dd.CUnit}</td>
                <td style={{ textAlign: "left" }}>{dd.Notes}</td>
              </tr>);
            }
          }
          Dom.push(<Fragment>
            <h4 style={{ textAlign: "center", marginTop: "100px" }}>Surat Jalan</h4>
            <div className='d-flex justify-content-between align-items-start'>
              <table>
                <tbody>
                  <tr> <td>Tanggal</td> <td>:</td> <td>{tanggalIndo(this.state.Detail.DocDate)}</td></tr>
                  <tr> <td>No. SJ</td> <td>:</td> <td>{this.state.Detail.NomorSuratJalan}</td></tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr> <td>Alamat</td> <td>:</td> <td>{this.state.Detail.AlamatKirim}</td></tr>
                  <tr> <td>Nama</td> <td>:</td> <td>{this.state.Detail.CardName}</td></tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <table className='table table-bordered table-sm' style={{ marginBottom: "0" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Nama Barang</th>
                  <th style={{ textAlign: "center" }}>Qty</th>
                  <th style={{ textAlign: "center" }}>Satuan</th>
                  <th style={{ textAlign: "center" }}>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                {tr}
              </tbody>
            </table>
          </Fragment>);
        }

        Dom.push(<Fragment>
          <div className='d-flex justify-content-between align-items-start'>
            <div style={{ textAlign: "center" }}>
              <h6 style={{ paddingBottom: "100px" }}>Administrasi</h6>
              <h6>(  {this.state.Admin}  )</h6>
            </div>
            <div style={{ textAlign: "center" }}>
              <h6 style={{ paddingBottom: "100px" }}>Sopir</h6>
              <h6>(  {this.state.Sopir}  )</h6>
            </div>
            <div style={{ textAlign: "center" }}>
              <h6 style={{ paddingBottom: "100px" }}>Penerima</h6>
              <h6>(  {this.state.Detail.CardName}  )</h6>
            </div>
          </div>
        </Fragment >);
      }
      document.getElementById('btnTutupModalCetak').click();
      this.setState({ DomPrintPreview: Dom });
      setTimeout(() => {
        window.print();
      }, 500);
    }

    async handleCetak2(e) {
      e.stopPropagation();
      e.preventDefault();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      if (Form.checkValidity()) {
        let sql = await api("/api", {
          act: "cetak",
          DocNumber: this.state.Detail.DocNumber,
          DocID: this.state.Detail.ID,
          LocationID: this.state.Lokasi,
          Profile: this.state.Detail.Profile,
          isPreview: this.state.isPreview,
          QtyResource: this.state.QtyResource,
          Detail: this.state.DetailProfile,
          PrintType: this.state.ModePrint
        });

        if (sql.status == "sukses") {
          btn.disabled = false;
          document.getElementById('btnTutupModalCetak').click();
        }
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    async modalSetting() {
      let sql = await api("/api", { act: "data profile" });
      if (sql.status == "sukses") {
        this.setState({ DataProfile: sql.data });
        const myModal = new window.bootstrap.Modal('#modalSetting', {});
        myModal.show('#modalSetting');
      } else {
        alert("data tidak di temukan");
      }
    }

    async handleSaveProfie(e) {
      console.log(this.state.DetailProfile);
      e.stopPropagation();
      e.preventDefault();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      if (Form.checkValidity()) {
        let sql = await api("/api", { act: "edit profile", data: this.state.DetailProfile });
        if (sql.status == "sukses") {
          btn.disabled = false;
          this.handleMain();
          document.getElementById('btnTutupModalEditProfile').click();
        } else {
          alert("Terjadi kesalahan ketika memproses");
          btn.disabled = false;
        }
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    async modalSendPrint(data) {
      let sql = await api("/api", { act: "detail", DocNumber: data.DocNumber, Profile: data.Profile });
      if (sql.status == "sukses") {
        this.setState({ isPreview: false, Detail: sql.data, DataDetail: sql.detail, DetailProfile: sql.profile, DataLokasi: sql.lokasi, Admin: sql.profile.Admin, Atasan: sql.profile.Atasan, Sopir: sql.profile.Sopir, PrintNotes: sql.profile.PrintNotes });
        const myModal = new window.bootstrap.Modal('#modalCetak', {});
        myModal.show('#modalCetak');
      } else {
        alert("data tidak di temukan");
      }
    }

    async handleShowWH() {
      let sql = await api("/api", { act: "data wh", q: this.state.qwh });
      if (sql.status == "sukses") {
        this.setState({ DataWH: sql.data });
        const myModal = new window.bootstrap.Modal('#modalWH', {});
        myModal.show('#modalWH');
      }
    }

    modalEditWH(data) {
      this.setState({ DetailWH: data });
      const myModal = new window.bootstrap.Modal('#modalEditWH', {});
      myModal.show('#modalEditWH');
    }

    modalDeleteWH(data) {
      this.setState({ DetailWH: data });
      const myModal = new window.bootstrap.Modal('#modalDeleteWH', {});
      myModal.show('#modalDeleteWH');
    }

    async handleSubmitWH(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      if (Form.checkValidity()) {
        let sql = await api("/api", {
          act: this.state.DetailWH.ID ? "edit wh" : "tambah wh",
          ID: this.state.DetailWH.ID,
          Code: this.state.DetailWH.Code,
          Nama: this.state.DetailWH.Nama,
          UserName: this.state.DetailWH.UserName,
          ProfileID: this.state.DetailWH.ProfileID,
          IsShowTonase: this.state.DetailWH.IsShowTonase
        });

        if (sql.status == "sukses") {
          btn.disabled = false;
          this.setState({ DataWH: sql.data });
          document.getElementById('btnTutupEditWH').click();
        } else {
          alert("Terjadi kesalahan ketika memproses");
          btn.disabled = false;
        }
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    async handleDeleteWH(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      if (Form.checkValidity()) {
        let sql = await api("/api", {
          act: "hapus wh",
          ID: this.state.DetailWH.ID
        });

        if (sql.status == "sukses") {
          btn.disabled = false;
          this.setState({ DataWH: sql.data });
          document.getElementById('btnTutupDeleteWH').click();
        } else {
          alert("Terjadi kesalahan ketika memproses");
          btn.disabled = false;
        }
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    async handleExprt(e) {
      e.stopPropagation();
      e.preventDefault();
      let Type = document.getElementById('edtTypeExport').value;
      if (Type == "Header") {
        let field = [
          { cap: "Tanggal", sort: "DocDate", type: "date" },
          { cap: "Nama", sort: "CardDetailName" },
          { cap: "No. Reff", sort: "DocNumber" },
          { cap: "No. SO", sort: "NomorSO" },
          { cap: "No. Invoice", sort: "Invoice" },
          { cap: "Faktur Pajak", sort: "FakturPajak" },
          { cap: "No. NPWP", sort: "TaxIDNumber" },
          { cap: "Nama NPWP", sort: "TaxIDName" },
          { cap: "Alamat NPWP", sort: "TaxIDAddress" },
          { cap: "No. SJ", sort: "NomorSuratJalan" },
          { cap: "Nomor KTP", sort: "NomorKTP" },
          { cap: "Nama KTP", sort: "NamaKTP" },
          { cap: "Alamat KTP", sort: "AlamatKTP" },
          { cap: "Alamat Kirim", sort: "AlamatKirim" },
          { cap: "Profile", sort: "Nama" }
        ]
        exportData(this.state.Data, 'MAPtx', field);
      } else {
        let sql = await api("/api", { act: "data detail", D1: this.state.D1, D2: this.state.D2 });
        if (sql.status == "sukses") {
          let field = [
            { cap: "Tanggal", sort: "DocDate", type: "date" },
            { cap: "Nama", sort: "CardDetailName" },
            { cap: "No. Reff", sort: "DocNumber" },
            { cap: "No. SO", sort: "NomorSO" },
            { cap: "No. Invoice", sort: "Invoice" },
            { cap: "Faktur Pajak", sort: "FakturPajak" },
            { cap: "No. NPWP", sort: "TaxIDNumber" },
            { cap: "Nama NPWP", sort: "TaxIDName" },
            { cap: "Alamat NPWP", sort: "TaxIDAddress" },
            { cap: "Nomor KTP", sort: "NomorKTP" },
            { cap: "Nama KTP", sort: "NamaKTP" },
            { cap: "Alamat KTP", sort: "AlamatKTP" },
            { cap: "No. SJ", sort: "NomorSuratJalan" },
            { cap: "Alamat Kirim", sort: "AlamatKirim" },
            { cap: "Barang", sort: "ItemName" },
            { cap: "Qty", sort: "CQtyUnit" },
            { cap: "Satuan", sort: "CUnit" },
            { cap: "Harga", sort: "CPrice", type: "number" },
            { cap: "Total", sort: "CTotal", type: "number" },
            { cap: "Keterangan", sort: "Notes" },
            { cap: "Profile", sort: "Nama" }
          ]
          exportData(sql.data, 'MAPtx', field);
        }
      }
    }

    nullSave(data) {
      if (data == null) {
        return "";
      } else if (data == "null" || data == "NULL") {
        return "";
      } else if (data == undefined) {
        return "";
      } else {
        return data;
      }
    }

    render() {
      return (
        <Fragment>
          <div className='konten'>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
              <div className="container-fluid">
                <a className="navbar-brand" href="#"></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="#">Data Sales</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#" onClick={() => this.handleShowWH()}>Warehouse</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#" onClick={() => this.handleModalTarikData()}>Tarik Data</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#" onClick={() => this.modalSetting()}>Setting</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" onClick={() => this.handleLogout()}>Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <div className='container'>
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <div className='d-flex justify-content-end align-items-center gap-2'>
                  <input type='text' className='form-control form-control-sm' value={this.state.D1Format}
                    onFocus={(e) => {
                      let D1 = this.state.D1;
                      this.setState({ D1Format: D1 });
                      e.target.type = "date";
                    }}

                    onBlur={(e) => {
                      e.target.type = "text";
                      let D1 = this.state.D1;
                      this.setState({ D1Format: tanggalIndo(D1) });
                    }}

                    onChange={(e) => this.setState({ D1: e.target.value, D1Format: e.target.value })} />

                  <input type='text' className='form-control form-control-sm' value={this.state.D2Format}
                    onFocus={(e) => {
                      let D2 = this.state.D2;
                      this.setState({ D2Format: D2 });
                      e.target.type = "date";
                    }}

                    onBlur={(e) => {
                      e.target.type = "text";
                      let D2 = this.state.D2;
                      this.setState({ D2Format: tanggalIndo(D2) });
                    }}

                    onChange={(e) => this.setState({ D2: e.target.value, D2Format: e.target.value })} />
                  <button className='btn btn-sm btn-primary' onClick={(e) => this.handleMain(e)}>Cari</button>
                  <button className='btn btn-sm btn-primary' data-bs-toggle="modal" data-bs-target="#modalExport">Export</button>
                </div>
              </div>
              <p></p>
              <div className='container-fluid'>
                <div className='table-responsive'>
                  <DataGrid dataSource={this.state.Data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true} style={{ width: "100%", minWidth: "1200px" }}>
                    <Column caption="Actions"
                      cellRender={(rowData) => (
                        <div>
                          <i className="material-icons" onClick={() => this.modalDetail(rowData.data)}>edit</i>
                          <i className="material-icons" onClick={() => this.modalDelete(rowData.data)}>delete</i>
                          <i className="material-icons" onClick={() => this.modalSendPrint(rowData.data)}>fax</i>
                        </div>
                      )}
                    />
                    {
                      this.state.Field.map((tr, ii) => {
                        if (tr.type == "number") {
                          return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                        } else if (tr.type == "date") {
                          return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                        } else if (tr.type == "datetime") {
                          return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                        } else {
                          return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                        }
                      })
                    }
                    <Paging defaultPageSize={50} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Summary>
                      <TotalItem column="ID" summaryType="count" />
                      <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                      <GroupItem column="GrandTotal" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Nominal : {0}" showInGroupFooter={false} alignByColumn={true} />
                    </Summary>
                    <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalExport" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleExprt(e)} noValidate >
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Export Data</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <select className='form-select form-select-sm' id='edtTypeExport'>
                      <option value="Header">Header</option>
                      <option value="Detail">Detail</option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupExportData' data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-primary" id='btnExport'>Export</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalTarikData" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleTarikData(e)} noValidate >
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Tarik Data</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='d-flex gap-2'>
                      <input type="text" className='form-control form-control-sm' value={this.state.sD1Format}
                        onFocus={(e) => {
                          let D1 = this.state.sD1;
                          this.setState({ sD1Format: D1 });
                          e.target.type = "date";
                        }}
                        onBlur={(e) => {
                          e.target.type = "text";
                          let D1 = this.state.sD1;
                          this.setState({ sD1Format: tanggalIndo(D1) });
                        }}

                        onChange={(e) => this.setState({ sD1: e.target.value, sD1Format: e.target.value })}
                      />

                      <input type="text" className='form-control form-control-sm' value={this.state.sD2Format}

                        onFocus={(e) => {
                          let D2 = this.state.sD2;
                          this.setState({ sD2Format: D2 });
                          e.target.type = "date";
                        }}
                        onBlur={(e) => {
                          e.target.type = "text";
                          let D2 = this.state.sD2;
                          this.setState({ sD2Format: tanggalIndo(D2) });
                        }}

                        onChange={(e) => this.setState({ sD2: e.target.value, sD2Format: e.target.value })} />
                    </div>
                    <p></p>
                    <div className='d-flex gap-2'>
                      <select className='form-select' value={this.state.Profile} onChange={(e) => this.setState({ Profile: e.target.value })} >
                        <option value="">Silahkan Pilih Profile</option>
                        {
                          this.state.DataProfile.map((opt, i) => {
                            return <option value={opt.NamaDatabase} key={i}>{opt.Nama}</option>
                          })
                        }
                      </select>
                      <button type="button" className="btn btn-primary" onClick={(e) => this.handleCariTrans(e)}>Cari</button>
                    </div>
                    <p></p>
                    <div className='table-responsive' style={{ maxHeight: "500px", overflowY: "scroll" }}>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="DocNumber" onChange={(e) => this.HandleChooseAll(e)} />
                                <label className="form-check-label" htmlFor="DocNumber">Doc. Number</label>
                              </div>
                            </th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Grand Total</th>
                            <th>Memo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.DataTrans.map((tr, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" value={tr.DocNumber} id={tr.DocNumber} onChange={(e) => this.handleChooseTrans(e, i)} checked={tr.Choose} />
                                      <label className="form-check-label" htmlFor={tr.DocNumber} style={{ color: tr.Status == 0 ? "black" : "red" }}>{tr.DocNumber}</label>
                                    </div>
                                  </td>
                                  <td>{tanggalIndo(tr.DocDate)}</td>
                                  <td>{tr.CardName}</td>
                                  <td>{numberFormat(tr.GrandTotal)}</td>
                                  <td>{tr.Memo}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupTrans' data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-primary" id='btnSubmit'>Tarik</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleEdit(e)} noValidate>
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">{this.state.Detail.Nama} - {this.state.Detail.DocNumber}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">

                    <div className='row mb-2'>
                      <div className='col-3'>
                        <div className='form-group'>
                          <label>Tanggal</label>
                          <input type="date" className='form-control form-control-sm' value={this.state.Detail.DocDate} onChange={(e) => {
                            let data = this.state.Detail;
                            data.DocDate = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi tanggal</div>
                        </div>

                        <div className='form-group'>
                          <label>Nama</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.CardDetailName} onChange={(e) => {
                            let data = this.state.Detail;
                            data.CardDetailName = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi Nama</div>
                        </div>

                        <div className='form-group'>
                          <label>Uang Muka</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.AmountOther}

                            onChange={(e) => {
                              let data = this.state.Detail;
                              let val = e.target.value;
                              data.AmountOther = val.replaceAll(",", "");
                              this.setState({ Detail: data });
                            }}

                            onFocus={(e) => {
                              let data = this.state.Detail;
                              let val = e.target.value;
                              data.AmountOther = val.replaceAll(",", "");
                              e.target.type = 'number';
                              this.setState({ Detail: data });
                            }}

                            onBlur={(e) => {
                              let data = this.state.Detail;
                              let val = e.target.value;
                              data.AmountOther = numberFormat(val);
                              e.target.type = 'text';
                              this.setState({ Detail: data });
                            }}

                          />
                          <div className='invalid-feedback'>Silahkan isi Nama</div>
                        </div>
                      </div>

                      <div className='col-3'>
                        <div className='form-group'>
                          <label>Invoice</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.Invoice} onChange={(e) => {
                            let data = this.state.Detail;
                            data.Invoice = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi invoice</div>
                        </div>

                        <div className='form-group'>
                          <label>No. Sales Order</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.NomorSO} onChange={(e) => {
                            let data = this.state.Detail;
                            data.NomorSO = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi No. Sales Order</div>
                        </div>

                        <div className='form-group'>
                          <label>No. Surat Jalan</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.NomorSuratJalan} onChange={(e) => {
                            let data = this.state.Detail;
                            data.NomorSuratJalan = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi No. Surat Jalan</div>
                        </div>

                        <div className='form-group'>
                          <label>Alamat Kirim</label>
                          <textarea className='form-control form-control-sm' value={this.state.Detail.AlamatKirim} onChange={(e) => {
                            let data = this.state.Detail;
                            data.AlamatKirim = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi Alamat Kirim</div>
                        </div>
                      </div>

                      <div className='col-3'>
                        <div className='form-group'>
                          <label>No. NPWP</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.TaxIDNumber} onChange={(e) => {
                            let data = this.state.Detail;
                            data.TaxIDNumber = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi NPWP</div>
                        </div>

                        <div className='form-group'>
                          <label>Nama NPWP</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.TaxIDName} onChange={(e) => {
                            let data = this.state.Detail;
                            data.TaxIDName = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi NPWP</div>
                        </div>

                        <div className='form-group'>
                          <label>Alamat NPWP</label>
                          <textarea className='form-control form-control-sm' value={this.state.Detail.TaxIDAddress} onChange={(e) => {
                            let data = this.state.Detail;
                            data.TaxIDAddress = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi Alamat</div>
                        </div>

                        <div className='form-group'>
                          <label>Faktur Pajak</label>
                          <input type="text" className='form-control form-control-sm' value={this.state.Detail.FakturPajak} onChange={(e) => {
                            let data = this.state.Detail;
                            data.FakturPajak = e.target.value;
                            this.setState({ Detail: data });
                          }} />
                          <div className='invalid-feedback'>Silahkan isi faktur pajak</div>
                        </div>

                      </div>

                      <div className='col-3'>
                        <div className='form-group'>
                          <label>No. KTP</label>
                          <input type="text" className='form-control form-control-sm'
                            inputmode="numeric"
                            value={this.nullSave(this.state.Detail.NomorKTP)} onChange={(e) => {
                              let data = this.state.Detail;
                              data.NomorKTP = e.target.value;
                              this.setState({ Detail: data });
                            }} />
                          <div className='invalid-feedback'>Silahkan isi No. KTP</div>
                        </div>

                        <div className='form-group'>
                          <label>Nama KTP</label>
                          <input type="text" className='form-control form-control-sm'
                            value={this.nullSave(this.state.Detail.NamaKTP)} onChange={(e) => {
                              let data = this.state.Detail;
                              data.NamaKTP = e.target.value;
                              this.setState({ Detail: data });
                            }} />
                          <div className='invalid-feedback'>Silahkan isi Nama KTP</div>
                        </div>

                        <div className='form-group'>
                          <label>Alamat KTP</label>
                          <input type="text" className='form-control form-control-sm'
                            value={this.nullSave(this.state.Detail.AlamatKTP)} onChange={(e) => {
                              let data = this.state.Detail;
                              data.AlamatKTP = e.target.value;
                              this.setState({ Detail: data });
                            }} />
                          <div className='invalid-feedback'>Silahkan isi Alamat KTP</div>
                        </div>

                      </div>
                    </div>
                    <p></p>
                    <div className='table-responsive' style={{ height: "400px", maxHeight: "400px", overflowY: "auto" }}>
                      <table className='table table-striped table-sm'>
                        <thead>
                          <tr>
                            <th width="50px"></th>
                            <th width="250px">Nama</th>
                            <th width="100px">Qty</th>
                            {/* <th width="250px">Qty Desc</th> */}
                            <th>Satuan</th>
                            <th>Harga</th>
                            <th>Jumlah</th>
                            <th>Catatan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.DataDetail.map((tr, ii) => {
                              if (tr.IsDelete != true) {
                                return (
                                  <tr key={ii}>
                                    <td>
                                      <span className='material-icons' type='button' onClick={(e) => {
                                        let data = this.state.DataDetail;
                                        data[ii].IsDelete = true;
                                        this.setState({ DataDetail: data });
                                      }}>delete</span>
                                    </td>

                                    <td><input type="text" className='form-control form-control-sm' value={tr.ItemName} onChange={(e) => {
                                      let data = this.state.DataDetail;
                                      data[ii].ItemName = e.target.value;
                                      this.setState({ DataDetail: data });
                                    }} /></td>

                                    <td><input type="text" className='form-control form-control-sm' value={tr.CQtyUnit}

                                      onChange={(e) => {
                                        let data = this.state.DataDetail;
                                        data[ii].CQtyUnit = e.target.value;
                                        this.setState({ DataDetail: data });
                                      }}

                                      onFocus={(e) => {
                                        let data = this.state.DataDetail;
                                        let val = e.target.value;
                                        data[ii].CQtyUnit = val.replaceAll(",", "");
                                        e.target.type = 'number';
                                        this.setState({ DataDetail: data });
                                      }}

                                      onBlur={(e) => {
                                        let data = this.state.DataDetail;
                                        let val = e.target.value;
                                        data[ii].CQtyUnit = numberFormat(val);
                                        let Qty = val;
                                        let Price = data[ii].CPrice != 0 ? data[ii].CPrice.replaceAll(",", "") : 0;
                                        let Total = Qty * Price;
                                        data[ii].CTotal = numberFormat(Total);
                                        e.target.type = 'text';
                                        this.setState({ DataDetail: data });
                                      }} /></td>

                                    {/* <td><input type="text" className='form-control form-control-sm' value={tr.CQtyDesc}

                                      onChange={(e) => {
                                        let data = this.state.DataDetail;
                                        data[ii].CQtyDesc = e.target.value;
                                        this.setState({ DataDetail: data });
                                      }} /></td> */}

                                    <td><input type="text" className='form-control form-control-sm' value={tr.CUnit} onChange={(e) => {
                                      let data = this.state.DataDetail;
                                      data[ii].CUnit = e.target.value;
                                      this.setState({ DataDetail: data });
                                    }} /></td>

                                    <td><input type="text" className='form-control form-control-sm' value={tr.CPrice}
                                      onChange={(e) => {
                                        let data = this.state.DataDetail;
                                        data[ii].CPrice = e.target.value;
                                        this.setState({ DataDetail: data });
                                      }}

                                      onFocus={(e) => {
                                        let data = this.state.DataDetail;
                                        let val = e.target.value;
                                        data[ii].CPrice = val.replaceAll(",", "");
                                        e.target.type = 'number';
                                        this.setState({ DataDetail: data });
                                      }}

                                      onBlur={(e) => {
                                        let data = this.state.DataDetail;
                                        let val = e.target.value;
                                        data[ii].CPrice = numberFormat(val);
                                        let Qty = data[ii].CQtyUnit != 0 ? data[ii].CQtyUnit.replaceAll(",", "") : 0;
                                        let Price = val;
                                        let Total = Price * Qty;
                                        data[ii].CTotal = numberFormat(Total);
                                        e.target.type = 'text';
                                        this.setState({ DataDetail: data });
                                      }} /></td>

                                    <td><input type="text" className='form-control form-control-sm' value={tr.CTotal}
                                      onChange={(e) => {
                                        let data = this.state.DataDetail;
                                        data[ii].CTotal = e.target.value;
                                        this.setState({ DataDetail: data });
                                      }} /></td>

                                    <td><textarea className='form-control form-control-sm' value={tr.Notes} onChange={(e) => {
                                      let data = this.state.DataDetail;
                                      data[ii].Notes = e.target.value;
                                      this.setState({ DataDetail: data });
                                    }} /></td>
                                  </tr>
                                )
                              }
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-sm btn-secondary" id='btnTutupModalEdit' data-bs-dismiss="modal">Batal</button>
                    <button type='button' className='btn btn-sm btn-success' onClick={(e) => {
                      let data = this.state.DataDetail;
                      data.push({
                        "ID": "baru",
                        "TransID": data[0].TransID,
                        "DocNumber": data[0].DocNumber,
                        "Recno": data[0].Recno,
                        "ItemID": data[0].ItemID,
                        "ReffItemID": data[0].ReffItemID,
                        "ItemCode": data[0].ItemCode,
                        "ItemName": "",
                        "DiscountDesc": data[0].DiscountDesc,
                        "Notes": data[0].Notes,
                        "TaxID": data[0].TaxID,
                        "NetPrice": data[0].NetPrice,
                        "NetPriceWeight": data[0].NetPriceWeight,
                        "LocationID": data[0].LocationID,
                        "DWeight": data[0].DWeight,
                        "DQtyDesc": data[0].DQtyDesc,
                        "DQty": data[0].DQty,
                        "DQtyUnit": data[0].DQtyUnit,
                        "DUnit": data[0].DUnit,
                        "DPrice": data[0].DPrice,
                        "DAmount": data[0].DAmount,
                        "DAddCost": data[0].DAddCost,
                        "DDiscP": data[0].DDiscP,
                        "DDiscV": data[0].DDiscV,
                        "DTotal": data[0].DTotal,
                        "DCOG": data[0].DCOG,
                        "DTaxAmount": data[0].DTaxAmount,
                        "DImportTaxV": data[0].DImportTaxV,
                        "DSubTotal": data[0].DSubTotal,
                        "CWeight": data[0].CWeight,
                        "CQtyDesc": "",
                        "CQty": data[0].CQty,
                        "CQtyUnit": 0,
                        "CUnit": data[0].CUnit,
                        "CPrice": 0,
                        "CAmount": data[0].CAmount,
                        "CDiscP": data[0].CDiscP,
                        "CDiscV": data[0].CDiscV,
                        "CTotal": 0,
                        "CCOG": data[0].CCOG,
                        "CTaxAmount": data[0].CTaxAmount,
                        "CImportTaxV": data[0].CImportTaxV,
                        "CSubtotal": data[0].CSubtotal,
                        "CurrPrice": data[0].CurrPrice,
                        "CurrAmount": data[0].CurrAmount,
                        "CurrAddCost": data[0].CurrAddCost,
                        "CurrDiscV": data[0].CurrDiscV,
                        "CurrTotal": data[0].CurrTotal,
                        "CurrTaxAmount": data[0].CurrTaxAmount,
                        "CurrImportTaxV": data[0].CurrImportTaxV,
                        "CurrSubTotal": data[0].CurrSubTotal,
                        "IsPromo": data[0].IsPromo,
                        "CoaID": data[0].CoaID,
                        "WarehouseID": data[0].WarehouseID,
                        "WarehouseName": data[0].WarehouseName,
                        "Processed": data[0].Processed,
                        "Profile": data[0].Profile
                      });
                      this.setState({ DataDetail: data });
                    }}>Tambah Item</button>
                    <button type="submit" onClick={() => this.setState({ modeEdit: "Edit" })} id="btnSubmit1" className="btn btn-sm btn-primary">Simpan</button>
                    <button type="submit" onClick={() => this.setState({ modeEdit: "Cetak" })} id="btnSubmit2" className="btn btn-sm btn-primary">Simpan & Cetak</button>
                  </div>
                </form>
              </div>
            </div>
          </div >

          <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleDelete(e)} noValidate >
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Hapus Data</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    Apakah Anda Yakin Hapus Data ini !!
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupModalDelete' data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-danger" id='btnSubmit'>Hapus</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalCetak" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleCetak2(e)} noValidate >
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Cetak Dokumen</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='form-group form-group-sm'>
                      <label>Mode Untuk</label>
                      <select className='form-select' value={this.state.ModePrint} onChange={(e) => this.setState({ ModePrint: e.target.value })} required >
                        <option value="invoice">Invoice</option>
                        <option value="surat jalan">Surat Jalan</option>
                      </select>
                    </div>
                    <div className='form-group form-group-sm'>
                      <label>Lokasi</label>
                      <select className='form-select' value={this.state.Lokasi} onChange={(e) => this.setState({ Lokasi: e.target.value })} required>
                        <option value="">Silahkan pilih Lokasi</option>
                        {
                          this.state.DataLokasi.map((opt, i) => {
                            return <option value={opt.ID} key={i}>{opt.Code}- {opt.Nama}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className='form-group form-group-sm'>
                      <label>Sumber Qty</label>
                      <select className='form-select' value={this.state.QtyResource} onChange={(e) => this.setState({ QtyResource: e.target.value })} required>
                        <option value="CQtyUnit">Qty</option>
                        <option value="CQtyDesc">Qty Desc</option>
                      </select>
                    </div>
                    {
                      this.state.ModePrint == "invoice" ?
                        <Fragment>
                          <div className='form-group form-group-sm'>
                            <label>Print Notes</label>
                            <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.PrintNotes} onChange={(e) => {
                              let Detail = this.state.DetailProfile;
                              Detail.PrintNotes = e.target.value;
                              this.setState({ DetailProfile: Detail });
                            }} />
                          </div>
                          <div className='form-group form-group-sm'>
                            <label>Nama Admin</label>
                            <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Admin} onChange={(e) => {
                              let Detail = this.state.DetailProfile;
                              Detail.Admin = e.target.value;
                              this.setState({ DetailProfile: Detail });
                            }} />
                          </div>
                          <div className='form-group form-group-sm'>
                            <label>Nama Penanggung jawab</label>
                            <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Atasan} onChange={(e) => {
                              let Detail = this.state.DetailProfile;
                              Detail.Atasan = e.target.value;
                              this.setState({ DetailProfile: Detail });
                            }} />
                          </div>
                        </Fragment>
                        :
                        <Fragment>
                          <div className='form-group form-group-sm'>
                            <label>Nama Admin</label>
                            <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Admin} onChange={(e) => {
                              let Detail = this.state.DetailProfile;
                              Detail.Admin = e.target.value;
                              this.setState({ DetailProfile: Detail });
                            }} />
                          </div>
                          <div className='form-group form-group-sm'>
                            <label>Sopir</label>
                            <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Sopir} onChange={(e) => {
                              let Detail = this.state.DetailProfile;
                              Detail.Sopir = e.target.value;
                              this.setState({ DetailProfile: Detail });
                            }} />
                          </div>
                        </Fragment>
                    }
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupModalCetak' data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-primary" id='btnSubmit'>Cetak</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalSetting" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleSaveProfie(e)} noValidate >
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Setting</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='form-group form-group-sm'>
                      <label>Profile</label>
                      <select className='form-select' value={this.state.Profile} onChange={(e) => {
                        let data = this.state.DataProfile;
                        let NamaDatabase = e.target.value;
                        let Detail = {};
                        for (let dd of data) if (dd.NamaDatabase == NamaDatabase) Detail = dd;
                        this.setState({ DetailProfile: Detail, Profile: NamaDatabase });
                      }} required >
                        <option value="">Silahkan pilih profile</option>
                        {
                          this.state.DataProfile.map((opt, i) => {
                            return <option value={opt.NamaDatabase} key={i}>{opt.Nama}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className='form-group form-group-sm'>
                      <label>Print Notes</label>
                      <textarea type='text' className='form-control form-control-sm' value={this.state.DetailProfile.PrintNotes} onChange={(e) => {
                        let data = this.state.DetailProfile;
                        data.PrintNotes = e.target.value;
                        this.setState({ DetailProfile: data })
                      }} />
                    </div>
                    <div className='form-group form-group-sm'>
                      <label>Nama Admin</label>
                      <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Admin} onChange={(e) => {
                        let data = this.state.DetailProfile;
                        data.Admin = e.target.value;
                        this.setState({ DetailProfile: data })
                      }} />
                    </div>
                    <div className='form-group form-group-sm'>
                      <label>Nama Penanggung jawab</label>
                      <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Atasan} onChange={(e) => {
                        let data = this.state.DetailProfile;
                        data.Atasan = e.target.value;
                        this.setState({ DetailProfile: data })
                      }} />
                    </div>
                    <div className='form-group form-group-sm'>
                      <label>Sopir</label>
                      <input type='text' className='form-control form-control-sm' value={this.state.DetailProfile.Sopir} onChange={(e) => {
                        let data = this.state.DetailProfile;
                        data.Sopir = e.target.value;
                        this.setState({ DetailProfile: data })
                      }} />
                    </div>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="chkTonase" checked={this.state.DetailProfile.IsShowTonase} onChange={(e) => {
                        let Detail = this.state.DetailProfile;
                        Detail.IsShowTonase = e.target.checked == true ? 1 : 0;
                        this.setState({ DetailProfile: Detail });
                      }} />
                      <label className="form-check-label" htmlFor="chkTonase">Tampilkan Tonase</label>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupModalEditProfile' data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-primary" id='btnSubmit'>Simpan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalWH" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">Data Warehouse</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <button className='btn btn-primary btn-sm' onClick={() => this.modalEditWH({})} >Tambah Warehouse</button>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th>Actions</th>
                          <th>Kode</th>
                          <th>Nama</th>
                          <th>Username</th>
                          <th>Profile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.DataWH.map((tr, i) => {
                            return (
                              <tr>
                                <td>
                                  <i className='material-icons' style={{ cursor: "pointer" }} onClick={(e) => this.modalEditWH(tr)}>edit</i>
                                  <i className='material-icons' style={{ cursor: "pointer" }} onClick={(e) => this.modalDeleteWH(tr)}>delete</i>
                                </td>
                                <td>{tr.Code}</td>
                                <td>{tr.Nama}</td>
                                <td>{tr.UserName}</td>
                                <td>{tr.Profile}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" id='btnTutupWH' data-bs-dismiss="modal">Tutup</button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalEditWH" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleSubmitWH(e)} noValidate>
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">{this.state.DetailWH == {} ? "Tambah" : "Edit"} Warehouse</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='table-responsive'>
                      <input type="hidden" name='act' value={this.state.DetailWH.ID ? "edit wh" : "tambah wh"} />
                      <input type="hidden" name='ID' value={this.state.DetailWH.ID} />
                      <div className='form-group'>
                        <label>Kode</label>
                        <input name='Code' className='form-control' value={this.state.DetailWH.Code} onChange={(e) => {
                          let Detail = this.state.DetailWH;
                          Detail.Code = e.target.value;
                          this.setState({ DetailWH: Detail });
                        }} required />
                      </div>
                      <div className='form-group'>
                        <label>Nama</label>
                        <input name='Nama' className='form-control' value={this.state.DetailWH.Nama} onChange={(e) => {
                          let Detail = this.state.DetailWH;
                          Detail.Nama = e.target.value;
                          this.setState({ DetailWH: Detail });
                        }} required />
                      </div>
                      <div className='form-group'>
                        <label>UserName</label>
                        <input name='UserName' className='form-control' value={this.state.DetailWH.UserName} onChange={(e) => {
                          let Detail = this.state.DetailWH;
                          Detail.UserName = e.target.value;
                          this.setState({ DetailWH: Detail });
                        }} required />
                      </div>
                      <div className='form-group'>
                        <label>Password</label>
                        <input name='UserName' className='form-control' value={this.state.DetailWH.Pwd} onChange={(e) => {
                          let Detail = this.state.DetailWH;
                          Detail.Pwd = e.target.value;
                          this.setState({ DetailWH: Detail });
                        }} />
                      </div>
                      <div className='form-group'>
                        <label>Profile</label>
                        <select name='PrpfileID' className='form-select' value={this.state.DetailWH.ProfileID} onChange={(e) => {
                          let Detail = this.state.DetailWH;
                          Detail.ProfileID = e.target.value;
                          this.setState({ DetailWH: Detail });
                        }} required>
                          <option value="">Profile</option>
                          {
                            this.state.DataProfile.map((opt, i) => {
                              return <option value={opt.ID}>{opt.Nama}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupEditWH' data-bs-dismiss="modal">Tutup</button>
                    <button type="submit" className="btn btn-primary" >Simpan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalDeleteWH" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => this.handleDeleteWH(e)} noValidate>
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">{this.state.DetailWH == {} ? "Tambah" : "Edit"} Warehouse</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='table-responsive'>
                      <input type="hidden" name='act' value="hapus wh" />
                      <input type="hidden" name='ID' value={this.state.DetailWH.ID} />
                      <h6>Apakah anda yakin akan menghapus data ini!</h6>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id='btnTutupDeleteWH' data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-danger" >Hapus</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className='print div-print'>
            {
              this.state.DomPrintPreview
            }
          </div>
        </Fragment >
      )
    }
  }

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Username: "",
        Pwd: ""
      };
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      let Form = e.target;
      let btn = Form.querySelector('button[type="submit"]');
      btn.disabled = true;
      if (Form.checkValidity()) {
        let sql = await api("/api", { act: "login", Username: this.state.Username, Pwd: this.state.Pwd });

        if (sql.status == "sukses") {
          btn.disabled = false;
          localStorage.setItem("TokenData", sql.Token);
          window.location.reload();
        } else {
          btn.disabled = false;
          alert(sql.pesan);
        }
      } else {
        Form.classList.add('was-validated');
        btn.disabled = false;
      }
    }

    render() {
      return (
        <Fragment>
          <div className='background-container'>
            <div className="container">
              <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card shadow">
                    <div className="card-title text-center border-bottom">
                      <h2 className="p-3">Login</h2>
                    </div>
                    <div className="card-body content">
                      <form onSubmit={(e) => this.handleLogin(e)} noValidate>
                        <div className="mb-4">
                          <label htmlFor="username" className="form-label">Username/Email</label>
                          <input type="text" className="form-control form-control-sm" id="username" value={this.state.Username} onChange={(e) => this.setState({ Username: e.target.value })} required />
                          <div className='invalid-feedback'>Silahkan isi username / Email</div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="password" className="form-label">Password</label>
                          <input type="password" className="form-control form-control-sm" id="password" value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required />
                          <div className='invalid-feedback'>Silahkan isi kata sandi</div>
                        </div>
                        <div className="d-grid">
                          <button type="submit" className="btn text-light btn-primary">Login</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }

  if (Token) {
    return <Main />;
  } else {
    return <Login />;
  }
}

export default App;
